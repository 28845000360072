/* eslint-disable @typescript-eslint/naming-convention */
import classnames from 'classnames';
import React from 'react';

type RowNumber = 1 | 2 | 3 | 4 | 6 | 12;

interface RowClass {
    1: string
    2: string
    3: string
    4: string
    6: string
    12: string
}

interface RowObject {
    xs: RowClass
    sm: RowClass
    md: RowClass
    lg: RowClass
    xl: RowClass
}

const gridClasses: RowObject = {
    xs: {
        1: 'grid-cols-1',
        2: 'grid-cols-2',
        3: 'grid-cols-3',
        4: 'grid-cols-4',
        6: 'grid-cols-6',
        12: 'grid-cols-12',
    },
    sm: {
        1: 'sm:grid-cols-1',
        2: 'sm:grid-cols-2',
        3: 'sm:grid-cols-3',
        4: 'sm:grid-cols-4',
        6: 'sm:grid-cols-6',
        12: 'sm:grid-cols-12',
    },
    md: {
        1: 'md:grid-cols-1',
        2: 'md:grid-cols-2',
        3: 'md:grid-cols-3',
        4: 'md:grid-cols-4',
        6: 'md:grid-cols-6',
        12: 'md:grid-cols-12',
    },
    lg: {
        1: 'lg:grid-cols-1',
        2: 'lg:grid-cols-2',
        3: 'lg:grid-cols-3',
        4: 'lg:grid-cols-4',
        6: 'lg:grid-cols-6',
        12: 'lg:grid-cols-12',
    },
    xl: {
        1: 'xl:grid-cols-1',
        2: 'xl:grid-cols-2',
        3: 'xl:grid-cols-3',
        4: 'xl:grid-cols-4',
        6: 'xl:grid-cols-6',
        12: 'xl:grid-cols-12',
    },
};

interface ColumnSet {
    xs?: RowNumber
    sm?: RowNumber
    md?: RowNumber
    lg?: RowNumber
    xl?: RowNumber
}

const getColsClassNames = (cols: RowNumber | ColumnSet): string[] => {
    if (typeof cols === 'number') {
        return [gridClasses.xs[cols]];
    }

    return [
        gridClasses.xs[cols?.xs || 1],
        gridClasses.sm[cols?.sm || 2],
        gridClasses.md[cols?.md || 6],
        gridClasses.lg[cols?.lg || 12],
        gridClasses.xl[cols?.xl || cols?.lg || 12],
    ];
};

interface Props {
    children: React.ReactNode;
    cols: RowNumber | ColumnSet;
    noGap?: boolean;
    className?: string;
}

const Row: React.FC<Props> = ({
    children,
    cols,
    noGap = false,
    className = '',
}) => (
    <div
        className={classnames(className, 'grid', getColsClassNames(cols), {
            'gap-x-5 xl:gap-x-8': !noGap,
        })}
    >
        {children}
    </div>
);

export default Row;
