/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onRouteUpdate = ({ location, prevLocation }) => {
    // find if we've got a hash defined - if we do - scroll to it
    const hash = window.gatsby_router_hash;
    const scrollTo = () => {
        const scrollOffset = -100;
        const elementToScrollTo = document.getElementById(hash.substr(1));
        if (elementToScrollTo) {
            const top = elementToScrollTo.getBoundingClientRect().top + window.scrollY + scrollOffset;
            window.scrollTo({ top, behavior: 'smooth' });
        }
    };

    if (hash) {
        if (prevLocation?.pathname === location.pathname) {
            // Don't wait for transition if page is the same
            scrollTo();
        } else {
            setTimeout(() => {
                scrollTo();
            }, 300); // length of Framer exit transition
        }
        window.gatsby_router_hash = undefined;
    }
};

/*
* If the pathnames are the same, and there is a router hash, don't use in-built page scroll updating
* */
export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => !(prevRouterProps?.location?.pathname
    === routerProps?.location?.pathname && window.gatsby_router_hash);
