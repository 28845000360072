import { graphql, useStaticQuery } from 'gatsby';

type SiteMetadataReturnable = {
    readonly title: string | null;
    readonly description: string | null;
    readonly author: string | null;
    readonly siteUrl: string | null;
    readonly email: string | null;
    readonly bookingUrl: string | null
} | null | undefined;

const useSiteMetadata = (): SiteMetadataReturnable => {
    const data = useStaticQuery<Queries.SiteMetaDataHookQuery>(graphql`
        query SiteMetaDataHook {
            site {
                siteMetadata {
                    title
                    description
                    author
                    siteUrl
                    email
                    bookingUrl
                }
            }
        }
    `);
    return data?.site?.siteMetadata;
};

export default useSiteMetadata;
