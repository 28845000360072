exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-headless-shopify-tsx": () => import("./../../../src/pages/headless-shopify.tsx" /* webpackChunkName: "component---src-pages-headless-shopify-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-shopify-agentur-koeln-tsx": () => import("./../../../src/pages/shopify-agentur-koeln.tsx" /* webpackChunkName: "component---src-pages-shopify-agentur-koeln-tsx" */),
  "component---src-pages-shopify-migration-tsx": () => import("./../../../src/pages/shopify-migration.tsx" /* webpackChunkName: "component---src-pages-shopify-migration-tsx" */),
  "component---src-pages-shopify-plus-agentur-tsx": () => import("./../../../src/pages/shopify-plus-agentur.tsx" /* webpackChunkName: "component---src-pages-shopify-plus-agentur-tsx" */)
}

